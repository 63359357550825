/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isNotNil } from "~/tools";
import { isDoubleFilterSelected } from "../helpers";
import { FiltersProp } from "../types";

type Props = {
  filters: FiltersProp;
  updateFilters: (filter: FiltersProp) => void;
};

export function useFilters({ filters, updateFilters }: Props) {
  const {
    assignmentUpdatedFrom,
    assignmentUpdatedTo,
    unassigned,
    lifeSpanFrom,
    lifeSpanTo,
  } = filters;

  const isUnallocatedFilterSelected =
    isDoubleFilterSelected(assignmentUpdatedFrom, assignmentUpdatedTo) ||
    unassigned;

  const isLifeSpanFilterSelected = isNotNil(lifeSpanFrom || lifeSpanTo);

  const clearUnallocatedFilters = () => {
    updateFilters({
      unassigned: undefined,
      assignmentUpdatedFrom: undefined,
      assignmentUpdatedTo: undefined,
      unassignedExclude: undefined,
    });
  };

  const clearCostFilter = () =>
    updateFilters({
      costFrom: undefined,
      costTo: undefined,
      costExclude: undefined,
    });

  const clearWastePolicyFilter = () =>
    updateFilters({
      wastePolicy: undefined,
      wastePolicyExclude: undefined,
    });

  const clearApplicationFilter = () =>
    updateFilters({
      application: undefined,
      environment: undefined,
      environmentExclude: undefined,
    });

  const clearProviderFilter = () =>
    updateFilters({ provider: undefined, providerExclude: undefined });

  const clearRegionFilter = () =>
    updateFilters({ region: undefined, regionExclude: undefined });

  const clearAccountFilter = () =>
    updateFilters({ account: undefined, accountExclude: undefined });

  const clearCategoryFilter = () =>
    updateFilters({ category: undefined, categoryExclude: undefined });

  const clearTypeSubtypeFilter = () =>
    updateFilters({
      typeSubtype: undefined,
      typeSubtypeExclude: undefined,
    });

  const clearTagsFilter = () => {
    updateFilters({
      tags: undefined,
      tagsExclude: undefined,
    });
  };

  const clearMetadataFilter = () =>
    updateFilters({
      metadata: undefined,
      metadataExclude: undefined,
    });

  const clearDiscoveryTimeFilter = () =>
    updateFilters({
      firstSeenAtFrom: undefined,
      firstSeenAtTo: undefined,
      firstSeenAtExclude: undefined,
    });

  const clearLifeSpanFilter = () =>
    updateFilters({
      lifeSpanFrom: undefined,
      lifeSpanTo: undefined,
      lifeSpanExclude: undefined,
    });

  const clearExternalIntegrationFilter = () =>
    updateFilters({
      externalIntegration: undefined,
      externalIntegrationExclude: undefined,
    });

  return {
    isUnallocatedFilterSelected,
    clearUnallocatedFilters,
    isLifeSpanFilterSelected,
    clearCostFilter,
    clearWastePolicyFilter,
    clearApplicationFilter,
    clearProviderFilter,
    clearRegionFilter,
    clearAccountFilter,
    clearCategoryFilter,
    clearTypeSubtypeFilter,
    clearTagsFilter,
    clearMetadataFilter,
    clearDiscoveryTimeFilter,
    clearLifeSpanFilter,
    clearExternalIntegrationFilter,
  };
}
