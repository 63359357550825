/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, Link } from "react-router-dom";
import styled from "styled-components";
import {
  ExtendedTooltip,
  FlexContainer,
  ModalConfirm,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { MappedOrgUnitsV2 } from "../types";

type Props = {
  orgUnit: MappedOrgUnitsV2;
};

export function OrgUnitTag({ orgUnit }: Props) {
  const [isOpened, toggleIsOpened] = useToggle(false);

  return (
    <>
      <FlexContainer>
        <OrgUnitTagWrapper
          showBorder
          css={{
            padding: `calc(${theme.spacing.spacing02} - 1px) ${theme.spacing.spacing04}`,
          }}
        >
          <FlexContainer columnGap={theme.spacing.spacing02}>
            <div
              css={{
                width: "0.75rem",
                height: "0.75rem",
                backgroundColor: orgUnit.color ?? "#c0c0c0",
                borderRadius: theme.radius.sm,
              }}
            />
            <Link
              to={generatePath(ROUTES.businessContexts.details, {
                structureId: orgUnit.parentId,
                unitId: orgUnit.id,
              })}
            >
              <Text size="sm" mb={0}>
                {orgUnit.name}
              </Text>
            </Link>
          </FlexContainer>
        </OrgUnitTagWrapper>
        <OrgUnitTagWrapper
          css={{
            padding: `${theme.spacing.spacing01} ${theme.spacing.spacing03}`,
          }}
          onClick={toggleIsOpened}
        >
          <ExtendedTooltip caption="Detach Environment">
            <SVGIcon name="brokenLink" size="sm" />
          </ExtendedTooltip>
        </OrgUnitTagWrapper>
      </FlexContainer>
      <ModalConfirm
        isOpen={isOpened}
        contentLabel="Detachment"
        actionLabel="Confirm"
        confirm={noop}
        onClose={toggleIsOpened}
      >
        Do you want to detach {orgUnit.envName} from {orgUnit.name}?
      </ModalConfirm>
    </>
  );
}

const OrgUnitTagWrapper = styled(FlexContainer)<{ showBorder?: boolean }>`
  background-color: ${theme.color.background.ui04};
  border-radius: ${theme.radius.sm};
  cursor: pointer;
  border-right: ${({ showBorder = false }) =>
    showBorder ? `solid 1px ${theme.color.border.border02}` : undefined};

  svg {
    margin-top: -4px;
  }

  &:hover {
    background-color: ${theme.color.background.ui01};
  }
`;
