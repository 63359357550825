/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudAccountStatus, Maybe, Person, SearchSortOrder } from "~/tools";

export enum CloudProviderType {
  Cloud = "CLOUD",
  Kubernetes = "KUBERNETES",
  Openshift = "OPENSHIFT",
  Vmware = "VMWARE",
}

export enum Provider {
  Aws = "AWS",
  Azure = "AZURE",
  External = "EXTERNAL",
  Gcp = "GCP",
  Hybrid = "HYBRID",
  Ibmcloud = "IBMCLOUD",
  Kubernetes = "KUBERNETES",
  Nordcloud = "NORDCLOUD",
  Openshift = "OPENSHIFT",
  Services = "SERVICES",
  Vmware = "VMWARE",
}

export enum ProviderTab {
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
  IBMCLOUD = "IBMCLOUD",
  KUBERNETES = "KUBERNETES",
  OPENSHIFT = "OPENSHIFT",
  VMWARE = "VMWARE",
}

export type CloudAccount = {
  id: string;
  nid: string;
  provider: Provider;
  providerId: string;
  name: string;
  displayName?: Maybe<string>;
  description?: Maybe<string>;
  activated: boolean;
  metadata?: Maybe<string>;
  chargeTypeCosts?: Maybe<ChargeTypeCost[]>;
  cloudStatus?: CloudAccountStatus;
  providerType?: CloudProviderType;
  contacts?: Maybe<Maybe<Person>[]>;
  owner?: Maybe<Person>;
};

type ChargeTypeCost = {
  chargeType: string;
  cost: string;
};

export type CloudAccountsResponse = {
  accounts: CloudAccount[];
  count: number;
  pages: number;
};

export type CloudSummaryTabData = {
  name: ProviderTab;
  movedToK8s?: number;
  movedToOc?: number;
  count?: Maybe<number>;
  cost?: Maybe<string>;
  hasWarning: boolean;
};

export type ProviderStatus = {
  [CloudAccountStatus.FullAccess]: boolean;
  [CloudAccountStatus.BillingOnly]: boolean;
  [CloudAccountStatus.ApiOnly]: boolean;
  [CloudAccountStatus.NoAccess]: boolean;
  [CloudAccountStatus.NoData]: boolean;
};

export type ProviderFilterValues = {
  [Provider.Aws]: boolean;
  [Provider.Azure]: boolean;
  [Provider.Gcp]: boolean;
};

export type ProvidersForFiltering = Extract<
  Provider,
  Provider.Aws | Provider.Azure | Provider.Gcp
>;

export type StatusFilterCheckboxProps = {
  label: string;
  description: string;
  status: CloudAccountStatus;
  icon:
    | "cloudDataOnly"
    | "cloudOffline"
    | "cloudOnline"
    | "cloudQuestionMark"
    | "cloudWithDollar";
  color: "danger" | "success" | "warning" | undefined;
};

export type CloudAccountsQueryState = {
  provider: ProviderTab;
  status: string[];
  providerFilter: ProvidersForFiltering[];
  page: number;
  limit: number;
  query?: Maybe<string>;
  chargeType?: string;
  order?: SearchSortOrder;
  excludeStatus?: true;
  excludeProvider?: true;
};

export type AccountOwner = Maybe<{ id: string; name: string; email: string }>;

export type MovedCloudAccountTotalCosts = {
  movedToK8sFromAwsTotalCost: number | undefined;
  movedToOcFromAwsTotalCost: number | undefined;
  movedToK8sFromGcpTotalCost: number | undefined;
  movedToOcFromGcpTotalCost: number | undefined;
  movedToK8sFromAzureTotalCost: number | undefined;
  movedToOcFromAzureTotalCost: number | undefined;
  movedToK8sFromIBMCloudTotalCost: number | undefined;
  movedToOcFromIBMCloudTotalCost: number | undefined;
};

export enum AdditionalSettingsFields {
  DISPLAY_NAME = "displayName",
  DESCRIPTION = "description",
  CONTACT_PERSON = "contactPerson",
}

export enum AwsFormFields {
  ACCOUNT_IDS = "accountIds",
  ACCOUNT_OWNER = "accountOwner",
  ROLE_EXTERNAL_ID = "roleExternalID",
  ROLE_NAME = "roleName",
}

export enum AzureFormFields {
  SUBSCRIPTION_IDS = "subscriptionIds",
  ACCOUNT_OWNER = "accountOwner",
  APP_ID = "appId",
  PASSWORD = "password",
  TENANT = "tenant",
}

export enum GcpFormFields {
  ORGANISATION_ID = "organisationId",
  ACCOUNT_OWNER = "accountOwner",
  GCP_FILE = "gcpFile",
}

export enum HybridFormFields {
  CLUSTER_NAME = "clusterName",
  ACCOUNT_OWNER = "accountOwner",
}

export enum IbmCloudFormFields {
  ACCOUNT_ID = "accountId",
  ACCOUNT_OWNER = "accountOwner",
  API_KEY = "apiKey",
}

export enum VMWareFormFields {
  NAME = "name",
  OWNER = "owner",
}

/* eslint-disable @typescript-eslint/naming-convention */
export type GcpJsonData = {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};
/* eslint-enable */

export type ApplyFiltersHandler = ({
  statusValues,
  providerValues,
  isStatusExcluded,
  isProviderExcluded,
}: {
  statusValues: ProviderStatus;
  providerValues: ProviderFilterValues;
  isStatusExcluded: boolean;
  isProviderExcluded: boolean;
}) => void;
