/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ChartJobTableQuery } from "~/generated/graphql";
import {
  ChartType as CostAnalysisChartType,
  EstatePeriod,
  generateLinkParameters,
  getCostAnalysisTableColumns,
  Granularity,
  LinkQueryParameterName,
  mapStackBarChartColors,
  TableData,
} from "~/components";
import { useCurrency } from "~/hooks";
import { isNotNil } from "~/tools";
import {
  getExtraCostDetails,
  reorderGroups,
} from "~/views/estate/components/Charts/helpers";
import { mapEstatePeriodToPeriod } from "~/views/estate/helpers";
import { ChartJobData } from "../types";
import {
  generateDataWithSavingPlanCommitments,
  generateEstateRecordsGroups,
  getCategories,
} from "./helpers";

type Props = {
  chartData: ChartJobData | undefined;
  tableData: ChartJobTableQuery | undefined;
  granularity: Granularity;
  period: EstatePeriod;
  startDate: string;
  endDate: string;
  chartType: CostAnalysisChartType;
};

export function useEstateRecordsCost({
  chartData,
  tableData,
  granularity,
  period,
  startDate,
  endDate,
  chartType,
}: Props) {
  const { currency } = useCurrency();

  const granularData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? chartData?.chartJob?.data
        : chartData?.chartMonthlyJob?.data,
    [chartData, granularity]
  );

  const costPerGroupChartData = useMemo(
    () => generateDataWithSavingPlanCommitments(granularData?.timePoints ?? []),
    [granularData]
  );

  const costTableTimePoints = useMemo(
    () => (tableData?.chartJobTable?.data?.results ?? []).filter(isNotNil),
    [tableData]
  );

  const mappedPeriod = mapEstatePeriodToPeriod(period);

  const totalCost = Number(chartData?.chartJob?.data?.total ?? "0");

  const categories = getCategories(costPerGroupChartData ?? []);

  const costPerGroupTableData: TableData[] = useMemo(() => {
    const showTotalRow = period === EstatePeriod.RANGE;
    return generateEstateRecordsGroups(costTableTimePoints ?? [], showTotalRow);
  }, [costTableTimePoints, period]);

  const tableCount = tableData?.chartJobTable?.data?.count ?? 0;

  const chartKeys = useMemo(() => [...reorderGroups(categories)], [categories]);

  const stackBarColors = useMemo(
    () => mapStackBarChartColors(chartKeys),
    [chartKeys]
  );

  const allColumns = useMemo(
    () =>
      getCostAnalysisTableColumns({
        startDate,
        endDate,
        currency,
        data: costPerGroupTableData,
        period: mappedPeriod,
        chartType,
        colors: stackBarColors,
        linkParameters: getLinkParameters(chartType),
        headerName: mapHeaderName(chartType),
      }),
    [
      startDate,
      endDate,
      currency,
      costPerGroupTableData,
      period,
      stackBarColors,
      chartType,
      mappedPeriod,
    ]
  );

  const extraDetails = getExtraCostDetails(
    chartData?.chartJob?.data?.extraDetails ?? []
  );

  const firstColumnWidth = period !== EstatePeriod.RANGE ? "30%" : undefined;

  return {
    costPerGroupChartData,
    costPerGroupTableData,
    totalCost,
    tableCount,
    extraDetails,
    allColumns,
    stackBarColors,
    chartKeys,
    firstColumnWidth,
  };
}

function mapHeaderName(chartType: CostAnalysisChartType) {
  switch (chartType) {
    case CostAnalysisChartType.COST_PER_PROVIDER:
      return "Provider";
    case CostAnalysisChartType.COST_PER_REGION:
      return "Region";
    case CostAnalysisChartType.COST_PER_APPLICATION:
      return "Application Name";
    case CostAnalysisChartType.COST_PER_ENVIRONMENT_TYPE:
      return "Environment Type";
    case CostAnalysisChartType.COST_PER_ACCOUNT:
      return "Cloud Account";
    default:
      return "";
  }
}

function getLinkParameters(chartType: CostAnalysisChartType) {
  if (chartType === CostAnalysisChartType.COST_PER_APPLICATION) {
    return generateLinkParameters(LinkQueryParameterName.APPLICATION, []);
  }
  if (chartType === CostAnalysisChartType.COST_PER_ACCOUNT) {
    return generateLinkParameters(LinkQueryParameterName.ACCOUNT, []);
  }
  return undefined;
}
