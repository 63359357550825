/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Table } from "@nordcloud/gnui";
import { MappedEnvironmentV2 } from "~/views/applications/ApplicationsPage/components/types";
import { EnvironmentRow } from "./EnvironmentRow";

type Props = {
  environments: MappedEnvironmentV2[];
  applicationId: string;
};

export function EnvironmentsList({ environments, applicationId }: Props) {
  return (
    <Table css={{ tableLayout: "fixed" }}>
      <Table.thead>
        <Table.tr>
          <Table.th width="20%">Environment Name</Table.th>
          <Table.th>Organizational Units</Table.th>
          <Table.th width="10%">Savings Suggestion</Table.th>
          <Table.th width="6%">Costs</Table.th>
          <Table.th width="10%">Monthly Forecast</Table.th>
          <Table.th width="8%">Monthly Budget</Table.th>
          <Table.th width="6%" />
        </Table.tr>
      </Table.thead>
      <Table.tbody>
        {environments.map((environment) => (
          <EnvironmentRow
            key={environment.id}
            applicationId={applicationId}
            environment={environment}
          />
        ))}
      </Table.tbody>
    </Table>
  );
}
