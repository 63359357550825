/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Case, Default, Switch } from "react-if";
import styled from "styled-components";
import {
  ExtendedPopover,
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { MappedOrgUnitsV2 } from "../types";
import { OrgUnitPopover } from "./OrgUnitPopover";
import { OrgUnitTag } from "./OrgUnitTag";

type Props = {
  orgUnits: MappedOrgUnitsV2[];
};

export function OrganizationalUnitCell({ orgUnits }: Props) {
  return (
    <Switch>
      <Case condition={orgUnits.length < 2}>
        <FlexContainer columnGap={theme.spacing.spacing02}>
          {orgUnits.map((orgUnit) => (
            <OrgUnitTag key={orgUnit.id} orgUnit={orgUnit} />
          ))}
          <ExtendedTooltip caption="Attach Environment">
            <LinkButton>
              <SVGIcon name="link" size="sm" />
            </LinkButton>
          </ExtendedTooltip>
        </FlexContainer>
      </Case>
      <Default>
        <ExtendedPopover
          content={<OrgUnitPopover orgUnits={orgUnits} />}
          position="center"
          placement="top"
          triggerOn="click"
          closeOn="hover"
          trigger={
            <Text mb="0" css={{ cursor: "pointer" }} size="sm">
              {orgUnits.length} Organizational Units
            </Text>
          }
        />
      </Default>
    </Switch>
  );
}

const LinkButton = styled.div`
  background-color: ${theme.color.background.ui04};
  border-radius: ${theme.radius.sm};
  cursor: pointer;
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing02};

  svg {
    margin-top: -4px;
  }

  &:hover {
    background-color: ${theme.color.background.ui01};
  }
`;
