/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DataContainer, DataItem } from "~/components";
import { dateFormat } from "~/constants";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { inflect, noop, trimText } from "~/tools";
import { ApplicationEditSidebar } from "./ApplicationEditSidebar";
import { MappedApplicationV2 } from "./types";

type Props = {
  application: MappedApplicationV2;
};

export function ApplicationTitle({ application }: Props) {
  const today = dayjs().format(dateFormat.dayMonthYearShortNoComma);
  const { isOpen, close, toggle } = useDisclosure();

  return (
    <>
      <TitleWrapper>
        <FlexContainer
          columnGap={theme.spacing.spacing02}
          className="application-summary"
        >
          <SVGIcon name="application" />
          <VerticalLineBox>
            <Link
              to={generatePath(ROUTES.applications.details, {
                application: application.id,
              })}
            >
              {trimText(application.name, 35)}
            </Link>
            <FlexContainer columnGap={theme.spacing.spacing02}>
              <Text mb="0" size="sm" color={theme.color.text.text02}>
                {application.environmentsCount}{" "}
                {inflect("Environment")(application.environmentsCount)}
              </Text>
              <Text mb="0" size="sm" color={theme.color.text.text02}>
                |
              </Text>
              <Text mb="0" size="sm" color={theme.color.text.text02}>
                {application.resourceCount}{" "}
                {inflect("Estate Record")(application.resourceCount)}
              </Text>
            </FlexContainer>
          </VerticalLineBox>
        </FlexContainer>
        <FlexContainer columnGap={theme.spacing.spacing02}>
          <DataContainer marginBottom="0">
            <When condition={application.hasSavingsSuggestion}>
              <DataItem
                value={application.savingsSuggestion}
                label="Savings Suggestion"
                itemWidth="8.25rem"
                valueColor={
                  application.hasSavingsSuggestion
                    ? theme.color.text.success
                    : undefined
                }
              />
            </When>
            <DataItem
              value={application.cost}
              label={`Costs (1 - ${today})`}
              itemWidth="9.75rem"
            />
            <DataItem
              value={application.forecast}
              label="Monthly Forecast"
              itemWidth="7.75rem"
            />
            <DataItem
              value={application.budget}
              label="Monthly Budget"
              itemWidth="7.25rem"
            />
          </DataContainer>
          <Actions onEdit={toggle} />
        </FlexContainer>
      </TitleWrapper>
      <ApplicationEditSidebar
        isOpen={isOpen}
        appLoading={false}
        defaultValues={{
          ...application,
          description: application?.description ?? "",
          owner: application ?? undefined,
          contacts: application?.contacts ?? [],
        }}
        onClose={close}
      />
    </>
  );
}

type ActionsProps = {
  onEdit: () => void;
  onDelete?: () => void;
};

function Actions({ onEdit, onDelete = noop }: ActionsProps) {
  return (
    <FlexContainer columnGap={theme.spacing.spacing01}>
      <ExtendedTooltip caption="Edit Application">
        <Button
          type="button"
          icon="edit"
          severity="low"
          size="md"
          onClick={onEdit}
        />
      </ExtendedTooltip>
      <Button
        disabled
        type="button"
        icon="trash"
        severity="low"
        size="md"
        onClick={onDelete}
      />
    </FlexContainer>
  );
}

const VerticalLineBox = styled.div`
  padding-left: ${theme.spacing.spacing02};
  border-left: solid 2px ${theme.color.border.border01};
`;

/* stylelint-disable no-descending-specificity */
const TitleWrapper = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > .application-summary {
    width: unset;
  }
`;
